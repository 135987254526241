import {
  Box,
  Button,
  Pill,
  styled,
  Text,
  TextArea,
  Tooltip,
  useTheme
} from '@ltvco/refresh-lib/theme'
import {
  ContactSearchParams,
  enqueueSnackbar,
  openReportInNewTab,
  useReportRedirect,
  useServiceToken
} from '@ltvco/refresh-lib/v1';
import { SmartSearchResponse } from 'routes/SearchContact/SearchContactPage';
import { checkSingleResult } from './helper';
import { AutoAwesome, Info, Search } from '@mui/icons-material';
import { request, useScreenSize } from '@ltvco/refresh-lib/utils';
import { useAppConstantsContext } from '@ltvco/refresh-lib/ctx';
import { grey } from '@mui/material/colors';

export type SearchType =
  | 'username'
  | 'phone'
  | 'email'

const SmartSearchModalContainer = styled(Box)(({ theme }) => ({
  background: `linear-gradient(
    to top left, 
    #1F365D, 
    #1B61DB 100%, 
    #0E3475B2 70%)`,
  width: '100%',
  marginBottom: `${theme.spacing(2.5)}`,
  borderRadius: `${theme.spacing(1)}`,
  padding: `${theme.spacing(3)}`
}));

const SmartSearchHeadingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export interface SmartSearchModalProps {
  isFormCollapsed?: boolean;
  setHasSmartSearchResult: React.Dispatch<React.SetStateAction<boolean>>;
  setSmartSearchData: React.Dispatch<React.SetStateAction<SmartSearchResponse>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnRemoveAllFilters: () => void;
  setCollapseForm: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SmartSearchModal = ({
  isFormCollapsed,
  setHasSmartSearchResult,
  setSmartSearchData,
  setLoading,
  handleOnRemoveAllFilters,
  setCollapseForm,
  query,
  setQuery,
}: SmartSearchModalProps) => {
  const { isMedium } = useScreenSize();
  const { data } = useServiceToken('llm');
  const { redirectToSearchContactUrl } = useReportRedirect();
  const theme = useTheme();
  const {
    links: { baseUrl },
  } = useAppConstantsContext();

  const handleSearch = async () => {
    if (query.trim() === "") {
      return;
    }

    handleOnRemoveAllFilters();
    setHasSmartSearchResult(false);
    setCollapseForm(true);
    setLoading(true);

    const url = "/ms/insight/peopleSmartSearch";
    const token = data.token;

    let response;
    try {
      response = await request(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ search: query }),
      });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        'Something went wrong, please try a different search or try again later',
        { variant: 'error' }
      );
    };

    const smartSearchResponseData = response.search;

    if (!(smartSearchResponseData.names || smartSearchResponseData.usernames
      || smartSearchResponseData.emails || smartSearchResponseData.phones)) {
      setLoading(false);
      redirectToSearchContactUrl(smartSearchResponseData);
      return;
    };

    const {
      hasSingleResult,
      reportType,
      singleSearchQuery
    } = checkSingleResult(smartSearchResponseData);

    if (hasSingleResult) {
      if (reportType === 'name') {
        const data: ContactSearchParams = {
          name: singleSearchQuery,
          title: smartSearchResponseData.title,
          company: smartSearchResponseData.company,
          industry: smartSearchResponseData.industry,
          city: smartSearchResponseData.city,
          state: smartSearchResponseData.state,
        }
        redirectToSearchContactUrl(data);
      }
      else {
        reportType && typeof reportType === 'string' ? openReportInNewTab(
          {
            [reportType]: singleSearchQuery,
            searchType: reportType as SearchType,
          },
          baseUrl
        ) : null;

        setSmartSearchData(smartSearchResponseData);
        setHasSmartSearchResult(true);
      }
    }
    else {
      setSmartSearchData(smartSearchResponseData);
      setHasSmartSearchResult(true);
    }

    setLoading(false);
  };

  return (
    <>
      {(!isMedium || (isMedium && !isFormCollapsed)) && (
        <SmartSearchModalContainer>
          <SmartSearchHeadingContainer>
            <Box sx={{
              display: 'flex',
              gap: { xs: '15px', xl: '30px' }
            }}>
              <AutoAwesome sx={{
                fontSize: { xs: '30px', xl: '35px' },
                color: 'white'
              }} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                justifyContent: 'center'
              }}>
                <Text sx={{
                  color: 'white',
                  fontSize: { xs: '19px', xl: '25px' },
                  fontWeight: '700'
                }}>
                  Smart Search
                </Text>
                <Tooltip
                  title='Use Smart Search to find multiple contacts by phone, 
                                            email, or name all at once.'
                  placement='right'
                  arrow sx={{
                    '.MuiTooltip-tooltip': {
                      backgroundColor: grey[700],
                      color: 'white',
                      fontSize: '0.75rem',
                      lineHeight: 1.2,
                      maxWidth: 140
                    },
                  }}>
                  <Info sx={{
                    fontSize: { xs: '19px', xl: '25px' },
                    color: 'white',
                    ml: { xs: theme.spacing(0.5), xl: theme.spacing(1) },
                    alignSelf: 'center'
                  }} />
                </Tooltip>
              </Box>
            </Box>

            <Pill label='NEW!' sx={{
              backgroundColor: theme.palette.progress?.main,
              color: 'white',
              alignSelf: 'center',
              fontSize: { xs: '12px', xl: '15px' }
            }} />

          </SmartSearchHeadingContainer>
          <Box sx={{
            mt: '12px'
          }}>
            <Text sx={{
              color: 'white',
              fontSize: { xs: '14px', xl: '17px' }
            }} >
              Try the AI-powered Smart Search! <br /> Instantly search
              multiple phones, emails, names, and more—all in one go!
            </Text>
          </Box>
          <Box sx={{
            mt: '16px',
            mb: '8px'
          }}>
            <TextArea
              minRows={4}
              sx={{ width: '100%' }}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
              }}></TextArea>
          </Box>
          <Button fullWidth variant='contained' onClick={handleSearch}>
            Search
            <Search sx={{
              fontSize: '20px',
              ml: '4px'
            }} />
          </Button>
        </SmartSearchModalContainer>
      )}
    </>
  )
}

export default SmartSearchModal;